import { withoutEmptyProperties } from '../../shared/without-empty-properties';
import { projectApi } from '../baseConfig';
import { IOneId, IResponseList } from '../common.types';
import { getSearchParams } from '../utils';
import { IAssesmentUser, IGetAssesmentUserParams, IGetOneIdUsersParams, IUser } from './userApi.types';

export const getMe = () => projectApi.get('v1/me').json<IUser>();

export const getOneIdUsers = (params: IGetOneIdUsersParams) => {
  const searchParams = getSearchParams(withoutEmptyProperties(params));

  return projectApi
    .get('v3/search/oneid', {
      searchParams,
    })
    .json<IResponseList<IOneId>>();
};

export const getAssesmentUsers = (params: IGetAssesmentUserParams) => {
  const searchParams = getSearchParams(withoutEmptyProperties(params));

  return projectApi.get('v3/search/assmnt', { searchParams }).json<IResponseList<IAssesmentUser>>();
};
