import { useCallback, HTMLAttributes, ReactElement, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Pagination, Table } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Input } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/input';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { useDebounceFn } from 'ahooks';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import {
  DEFAULT_FORMAT_WITH_TIME,
  LIMIT_OPTIONS,
  RoleRu,
} from '../../../../../constants';
import { useAppStore } from '../../../../../stores/context.store';
import { SearchIcon } from '../../../../components/icons/search';
import { UserCirclePlusIcon } from '../../../../components/icons/user-circle-puls';
import { Stub } from '../../../../components/stub';
import {
  IEmployeeTableColumn,
  IEmployeeTableData,
} from './EmployeeTable.models';
import { useEmployeeTableStyles } from './EmployeeTable.styles';

const COLUMNS: IEmployeeTableColumn[] = [
  {
    name: 'Пользователь',
    key: 'user',
  },
  {
    name: 'Роль',
    key: 'role',
  },
  {
    name: 'E-mail',
    key: 'email',
  },
  {
    name: 'Номер телефона',
    key: 'phone',
  },
  {
    name: 'Дата добавления',
    key: 'createdAt',
  },
];

export const EmployeeTable = observer(() => {
  const { id } = useParams();
  const classes = useEmployeeTableStyles();

  const {
    organizationStore: { EmployeeTableStore },
  } = useAppStore();
  const {
    tableData,
    params,
    loading,
    total,
    maxPage,
    handleLimitPage,
    handleNextPage,
    handlePrevPage,
    setName,
    setEmail,
    setId,
  } = EmployeeTableStore;

  useEffect(() => {
    if (id) {
      setId(id);
    }
  }, [id]);

  const handleOnSearch = (value: string, key: 'user' | 'email') => {
    if (key === 'user') {
      setName(value);
    }
    if (key === 'email') {
      setEmail(value);
    }
  };

  const { run } = useDebounceFn(
    (event: ChangeEvent<HTMLInputElement>, key) => handleOnSearch(event.target.value, key),
    { wait: 500 },
  );

  const rowRender = useCallback(
    (
      row: IEmployeeTableData,
      rowProps: HTMLAttributes<HTMLDivElement>,
    ): ReactElement => (
      <div className={cn(rowProps.className, classes.row)}>
        <div className={cn(classes.tableBodyRow, classes.tableRowOrgName)}>
          {row.oneid?.fio}
        </div>
        <div className={classes.tableBodyRow}>{RoleRu[row.role]}</div>
        <div className={classes.tableBodyRow}>{row.oneid.email}</div>
        <div className={classes.tableBodyRow}>{row.oneid.phone}</div>
        <div className={classes.tableBodyRow}>
          {dayjs(row.updated_at).format(DEFAULT_FORMAT_WITH_TIME)}
        </div>
      </div>
    ),
    [],
  );

  const headerRowRender = ({
    name,
    key,
  }: IEmployeeTableColumn): ReactElement => (
    <div
      className={cn(classes.tableHeaderRow, {
        [classes.tableHeaderOrgName]: key === 'user',
      })}
    >
      <div>{name}</div>
      {(key === 'user' || key === 'email') && (
        <Input
          state="default"
          className={classes.searchField}
          placeholder="Поиск"
          icon={<SearchIcon />}
          onChange={(e) => run(e, key)}
          disabled={showStub}
        />
      )}
    </div>
  );

  const showStub = !loading && !total && !params.name && !params.email;

  return (
    <Paper className={classes.root}>
      <div className={classes.tableHeader}>
        <div className={classes.title}>Работники</div>
      </div>
      {showStub ? (
        <Stub
          className={classes.emptyRoot}
          textClassName={classes.emptyText}
          text="Здесь будут отображаться работники организации"
          icon={<UserCirclePlusIcon width={40} height={40} />}
        />
      ) : (
        <>
          <Table
            columns={COLUMNS}
            data={tableData}
            className={classes.table}
            headerRowRender={headerRowRender}
            rowRender={rowRender}
            loading={loading}
          />
          <Pagination
            limit={params.limit}
            limitsOptions={LIMIT_OPTIONS}
            onLimitChange={handleLimitPage}
            total={total}
            minPage={1}
            maxPage={maxPage}
            page={params.page}
            onPrevClick={handlePrevPage}
            onNextClick={handleNextPage}
            loading={loading}
          />
        </>
      )}
    </Paper>
  );
});
