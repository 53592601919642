import { FC, SVGProps } from 'react';
import cn from 'classnames';
import { LoaderIcon } from '../icons/loader';
import { useLoaderStyles } from './loader.styles';

export const Loader: FC<SVGProps<SVGSVGElement>> = (props) => {
  const classes = useLoaderStyles();

  const loaderProps: SVGProps<SVGSVGElement> = {
    ...props,
    className: cn(
      props.className,
      classes.root,
    ),
  };

  return (
    <LoaderIcon {...loaderProps} />
  );
};
