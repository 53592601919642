import { createUseStyles } from 'react-jss';

export const useAuthStyles = createUseStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    zIndex: 9,
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',
    padding: '1rem',
    maxWidth: '20rem',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    textAlign: 'center',
  },
}));
