import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/notification';
import { HTTPError } from 'ky';

interface IFetchActionOptions {
  showError?: boolean;
}

interface ICallbackFunctionsType<T> {
  successCallback?: (response: T) => void;
  finallyCallback?: () => void;
  errorCallback?: (error: HTTPError, message?: string) => void;
}

export const fetchAction = async <T>(
  promiseFunction: () => Promise<T>,
  callbackFunctions: ICallbackFunctionsType<T> = {},
  options: IFetchActionOptions = {
    showError: true,
  },
) => {
  const { successCallback, errorCallback, finallyCallback } = callbackFunctions;

  try {
    const data = await promiseFunction();

    if (successCallback) {
      successCallback(data);
    }
  }
  catch (error) {
    const httpError = error as HTTPError;
    const res = httpError.response ? (await httpError.response.json()) as { message: string } : undefined;
    const message = res?.message;

    if (errorCallback) {
      errorCallback(httpError, message);
    }

    if (options.showError && message) {
      toast({
        type: 'error',
        text: message,
      });
    }
  }
  finally {
    if (finallyCallback) {
      finallyCallback();
    }
  }
};
