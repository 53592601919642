import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { IOneId } from '../api/common.types';
import { IGetOneIdUsersParams, userApi } from '../api/userApi';
import { IRootStore } from '../models/stores/root.store';
import { fetchAction } from '../shared/fetchAction';
import { IHrModalTableData } from '../views/content/Organization/components/HrTable/components/AddHrModal/AddHrModal.models';

const DEFAULT_PARAMS = { page: 1, limit: 10 };

export class OneIdStore {

  public readonly rootStore: IRootStore;

  public data: IOneId[] = [];
  public total: number = 0;
  public loading: boolean = false;
  public error?: string = undefined;
  public params: IGetOneIdUsersParams = DEFAULT_PARAMS;


  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });

    reaction(() => this.params, this.getOneIdUsers);
  }


  public getOneIdUsers = (): void => {
    this.loading = true;
    this.error = undefined;

    fetchAction(() => userApi.getOneIdUsers(this.params), {
      successCallback: ({ items, totalCount }) => {
        this.data = this.params.page === 1 ? items : [...this.data, ...items];
        this.total = totalCount;
      },
      errorCallback: (_, message) => {
        this.error = message;
      },
      finallyCallback: () => {
        this.loading = false;
      },
    });
  };

  public setParams = (params: Partial<IGetOneIdUsersParams>): void => {
    this.params = { ...this.params, ...params };
  };

  public setPage = (page: number): void => {
    this.params = {
      ...this.params,
      page,
    };
  };

  public setLimit = (limit: number): void => {
    this.params = {
      ...this.params,
      limit,
    };
  };

  public setEmail = (email: string): void => {
    this.params = {
      ...this.params,
      page: 1,
      email,
    };
  };

  public reset = (): void => {
    runInAction(() => {
      this.data = [];
      this.error = undefined;
      this.loading = false;
      this.params = DEFAULT_PARAMS;
      this.total = 0;
    });
  };

  public get tableData(): IHrModalTableData[] {
    return this.data.map((user) => ({
      ...user,
      _id: user.id,
    }));
  }

}
