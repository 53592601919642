import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import cn from 'classnames';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useIntersect } from '../../../shared/use-intersect';
import { ChevronsIcon } from '../icons/chevrons';
import { FolderIcon } from '../icons/folder';
import { Loader } from '../loader';
import { ISidebarMenuOptionProps, ISidebarMenuProps } from './sidebar.models';
import { useSidebarMenuStyles } from './sidebar.styles';

export const Menu: React.FC<ISidebarMenuProps> = ({
  title,
  loading,
  icon,
  options,
  total,
  showTotal,
  loadNext,
}: ISidebarMenuProps): ReactElement => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const [view, setView] = useState(false);
  const classes = useSidebarMenuStyles({ open: isOpen });

  useEffect(() => {
    const isAllowToLoadNextPage = view && !loading && total > options.length;

    if (isAllowToLoadNextPage) {
      loadNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const handleToggle = (): void => {
    setIsOpen((prev: boolean) => !prev);
  };

  const iconSize = 20;

  return (
    <div className={classes.block} key={title}
    >
      <h3 className={classes.tab} onClick={handleToggle}>
        <div className={classes.icon}>
          {icon ?? <FolderIcon />}
        </div>
        <div className={classes.title}>
          <span>{title}</span>
          <div className={classes.title_icons}>
            {showTotal && !loading && (
              <span>{total}</span>
            )}
            <ChevronsIcon className={classes.chevron} />
          </div>
        </div>
      </h3>

      <div className={classes.scroll_box}>
        {options?.map((option, index) => {
          const optionCN = cn(classes.option, { [classes.option_active]: id === option.id });

          return (
            <OptionLink
              key={option.id}
              id={option.id}
              label={option.label}
              isLast={options.length - 1 === index}
              className={optionCN}
              setView={(inView): void => setView(inView)}
            />
          );
        })}
        {loading && (
          <div className={classes.loading_wrapper}>
            <Loader width={iconSize} height={iconSize} />
          </div>
        )}
      </div>
    </div>
  );
};

// "OptionLink" is designed to work correctly "useIntersect"
const OptionLink: FC<ISidebarMenuOptionProps> = ({
  id,
  label,
  isLast,
  className,
  setView,
}): ReactElement => {
  const lastItemRef = useRef<HTMLDivElement>(null);
  const { inView } = useIntersect(lastItemRef);

  useEffect(() => {
    setView(inView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Link
      key={`sidebar_link_${id}`}
      title={label}
      className={className}
      to={generatePath(BrowserRoute.organization, { id })}
    >
      <span ref={isLast ? lastItemRef : undefined}>
        {label}
      </span>
    </Link>
  );
};
