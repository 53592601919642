import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { useStyles } from './stub.styles';

interface StubProps {
  icon: ReactNode;
  text: string;
  gap?: number;
  className?: string;
  textClassName?: string;
}

const DEFAULT_STUB_CONTAINER_GAP = 24;

export const Stub: FC<StubProps> = ({
  icon,
  text,
  gap = DEFAULT_STUB_CONTAINER_GAP,
  className,
  textClassName,
}) => {
  const classes = useStyles({ gap });
  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.container}>
        <div className={classes.iconWrapper}>
          <div className={classes.icon}>{icon}</div>
        </div>
        <div className={cn(classes.text, textClassName)}>{text}</div>
      </div>
    </div>
  );
};
