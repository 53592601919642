import { FC, Fragment, PropsWithChildren, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { Loader } from '../../components/loader';
import { useAuthStyles } from './auth.styles';

export const Auth: FC<PropsWithChildren> = observer((props) => {
  const appStore = useContext(appStoreContext);

  const classes = useAuthStyles();

  useEffect(() => {
    appStore.user.getToken();
  }, []);

  const iconSize = 48;

  if (appStore.user.loading) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.block}>
          <Loader width={iconSize} height={iconSize} />
          <span>Выполняется проверка авторизации</span>
        </div>
      </div>
    );
  }

  if (typeof appStore.user.token !== 'string' && window.unione) {
    window.unione.goToAuth();

    return (
      <div className={classes.wrapper}>
        <div className={classes.block}>
          <Loader width={iconSize} height={iconSize} />
          <span>Переход на страницу авторизации...</span>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
});
