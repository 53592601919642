import { createUseStyles } from 'react-jss';

export const useTopBarStyles = createUseStyles((theme) => ({
  root: {
    position: 'relative',
  },
  line: {
    opacity: '0.5',
    background: theme.palette.lines.main,
    width: '100%',
    height: '2px',
    bottom: '0px',
    position: 'absolute',
  },
  content: {
    color: theme.palette.secondaryText.main,
    padding: '0 3rem',
    columnGap: '0.5rem',
    display: 'flex',
    position: 'relative',
    lineHeight: '20px',
    fontSize: 16,
    fontFamily: theme.fontFamily.sans,
    '@media(max-width: 1600px)': {
      fontSize: 14,
    },
  },
  link: {
    padding: '0.625rem 1rem',
    borderBottomWidth: '2px',
    columnGap: '0.625rem',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',

    '&:hover': {
      borderColor: theme.palette.helsinki.main,
    },
  },
  back_link: {
    paddingLeft: 0,
  },
  link_active: {
    padding: '0.625rem 1rem',
    borderBottomWidth: '2px',
    columnGap: '0.625rem',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  [`@media(max-width: ${theme.breakpoints.lg}px)`]: {
    content: {
      padding: {
        left: '2.5rem',
      },
    },
    link: {
      padding: '0.625rem',
      fontSize: '0.625rem',
    },
    link_active: {
      padding: '0.625rem',
      fontSize: '0.625rem',
    },
  },
}));
