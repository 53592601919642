import { createUseStyles } from 'react-jss';

export const useOrganizationListStyles = createUseStyles((theme) => ({
  root: {
    padding: {
      top: 20,
      right: 40,
      bottom: 42,
      left: 40,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 28,
    marginBottom: 16,
    fontFamily: theme.fontFamily.monserrat,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gloomyBlue.main,
  },
  tableHeader: {
    display: 'flex',
    marginBottom: 24,
    justifyContent: 'space-between',
  },
  searchField: {
    height: 40,
  },
  addOrganizationBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 20px !important',
    gap: 10,
  },
  table: {
    borderRadius: 10,
    border: '1px solid #E4E7F2',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  tableHeaderRow: {
    flex: 1,
    minWidth: 200,
    padding: ['16px', '11px'],
    borderBottomWidth: 1,
  },
  tableHeaderOrgName: {
    flex: 2,
  },
  tableBodyRow: {
    flex: 1,
    minWidth: 200,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    padding: ['16px', '12px'],
  },
  tableRowOrgName: {
    flex: 2,
    wordBreak: 'break-word',
  },
  tableRowStatusActive: {
    color: '#40BA21',
  },
  tableRowStatusBlockedBg: {
    backgroundColor: '#FEF2F2',
  },
  tableRowStatusBlocked: {
    color: '#EB5757',
  },
  emptyRoot: {
    padding: '146px 0',
  },
  row: {
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
}));
