import { HrTable, OrganizationInfo } from './components';
import { EmployeeTable } from './components/EmployeeTable';
import { ModeratorTable } from './components/ModeratorTable';

export const Organization = () => (
  <div>
    <OrganizationInfo />
    <ModeratorTable />
    <HrTable />
    <EmployeeTable />
  </div>
);
