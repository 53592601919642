import { useState, useCallback, HTMLAttributes, ReactElement, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Pagination,
  Table,
} from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Input } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/input';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { useDebounceFn } from 'ahooks';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { DEFAULT_FORMAT_WITH_TIME, LIMIT_OPTIONS } from '../../../../../constants';
import { useAppStore } from '../../../../../stores/context.store';
import { PlusIcon } from '../../../../components/icons/plus';
import { SearchIcon } from '../../../../components/icons/search';
import { TrashIcon } from '../../../../components/icons/trash';
import { UserCvIcon } from '../../../../components/icons/user-cv';
import { Stub } from '../../../../components/stub';
import { AddHrModal } from './components/AddHrModal/AddHrModal';
import { DetachHrModal } from './components/DetachHrModal/DetachHrModal';
import { IHrTableColumn, IHrTableData } from './HrTable.models';
import { useHrTableStyles } from './HrTable.styles';

const COLUMNS: IHrTableColumn[] = [
  {
    name: 'Пользователь',
    key: 'user',
  },
  {
    name: 'E-mail',
    key: 'email',
  },
  {
    name: 'Номер телефона',
    key: 'phone',
  },
  {
    name: 'Дата добавления',
    key: 'createdAt',
  },
  {
    name: '',
    key: 'deleteBtn',
  },
];

export const HrTable = observer(() => {
  const { id: organizationId } = useParams();
  const classes = useHrTableStyles();

  const { organizationStore } = useAppStore();
  const { hrTableStore } = organizationStore;
  const {
    tableData,
    loading,
    params,
    total,
    maxPage,
    handleLimitPage,
    handleNextPage,
    handlePrevPage,
    setId,
    setName,
    setEmail,
  } = hrTableStore;
  const { detachOrganizationHr } = organizationStore;
  const [addHrModal, setAddHrModal] = useState(false);

  const showStub = !total && !params.name && !params.email && !loading;

  useEffect(() => {
    if (organizationId) {
      setId(organizationId);
    }
  }, [organizationId]);

  const detachEmployeeModal = useModalState<string | undefined>();

  const handleCloseModal = () => {
    setAddHrModal(false);
  };

  const handleOpenConfirmDetach = (id: string) => {
    detachEmployeeModal.open(id);
  };

  const handleOnSearch = (value: string, key: 'user' | 'email') => {
    if (key === 'user') {
      setName(value);
    }
    if (key === 'email') {
      setEmail(value);
    }
  };

  const { run } = useDebounceFn(
    (event: ChangeEvent<HTMLInputElement>, key) => handleOnSearch(event.target.value, key),
    { wait: 500 },
  );

  const handleDetachHr = () => {
    if (organizationId && detachEmployeeModal.data) {
      detachOrganizationHr({
        organizationId,
        id: detachEmployeeModal.data,
      });

      detachEmployeeModal.close();
    }
  };

  const rowRender = useCallback(
    (
      row: IHrTableData,
      rowProps: HTMLAttributes<HTMLDivElement>,
    ): ReactElement => (
      <div className={cn(rowProps.className, classes.row)}>
        <div className={cn(classes.tableBodyRow, classes.tableRowOrgName)}>
          {row.oneid?.fio}
        </div>
        <div className={classes.tableBodyRow}>{row.oneid?.email}</div>
        <div className={classes.tableBodyRow}>{row.oneid?.phone}</div>
        <div className={classes.tableBodyRow}>{dayjs(row.updated_at).format(DEFAULT_FORMAT_WITH_TIME)}</div>
        <div className={classes.deleteHrBtn} onClick={() => handleOpenConfirmDetach(row.oneid.id)}>
          <TrashIcon />
        </div>
      </div>
    ),
    [],
  );

  const headerRowRender = ({ name, key }: IHrTableColumn): ReactElement => {
    if (key === 'deleteBtn') {
      return <div className={classes.deleteBtnColumn}></div>;
    }

    return (
      <div
        className={cn(classes.tableHeaderRow, {
          [classes.tableHeaderOrgName]: key === 'user',
        })}
      >
        <div>{name}</div>
        {(key === 'user' || key === 'email') && (
          <Input
            state="default"
            className={classes.searchField}
            placeholder="Поиск"
            icon={<SearchIcon />}
            onChange={(e) => run(e, key)}
            disabled={showStub}
          />
        )}
      </div>
    );
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.tableHeader}>
        <div className={classes.tableSearch}>
          <div className={classes.title}>HR</div>
        </div>
        <div>
          <Button
            size="none"
            className={classes.addHrBtn}
            onClick={() => setAddHrModal(true)}
          >
            <PlusIcon />
            Добавить HR
          </Button>
        </div>
      </div>
      {showStub ? (
        <Stub
          className={classes.emptyRoot}
          textClassName={classes.emptyText}
          text="Здесь будут отображаться HR организации"
          icon={<UserCvIcon width={40} height={40} />}
        />
      ) : (
        <>
          <Table
            columns={COLUMNS}
            data={tableData}
            className={classes.table}
            headerRowRender={headerRowRender}
            rowRender={rowRender}
            loading={loading}
          />
          <Pagination
            limit={params.limit}
            limitsOptions={LIMIT_OPTIONS}
            onLimitChange={handleLimitPage}
            total={total}
            minPage={1}
            maxPage={maxPage}
            page={params.page}
            onPrevClick={handlePrevPage}
            onNextClick={handleNextPage}
            loading={loading}
          />
        </>
      )}
      <AddHrModal isOpen={addHrModal} handleClose={handleCloseModal} />
      <DetachHrModal onConfirm={handleDetachHr} modalState={detachEmployeeModal} />
    </Paper>
  );
});
