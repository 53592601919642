/* eslint-disable @typescript-eslint/no-magic-numbers */
export const LIMIT_OPTIONS = [10, 15, 20];

export const REQUIRED_FIELD_ERROR_MESSAGE = 'Обязательное поле';

export const DEFAULT_FORMAT = 'DD.MM.YYYY';

export const DEFAULT_FORMAT_WITH_TIME = `${DEFAULT_FORMAT} HH:mm`;

export enum States {
  Default = 'default',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}

export enum RoleRu {
  'hr' = 'HR',
  'worker' = 'Сотрудник',
  'head' = 'Руководитель',
}
