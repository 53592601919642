import { FC, SVGProps } from 'react';

export const FolderIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 7.7282C3.75 6.89868 3.7512 6.34165 3.80033 5.92087C3.84758 5.51626 3.93034 5.33515 4.02698 5.21531C4.08287 5.14601 4.14601 5.08287 4.21531 5.02698C4.33515 4.93034 4.51626 4.84758 4.92087 4.80033C5.34165 4.7512 5.89868 4.75 6.7282 4.75C7.47532 4.75 7.67272 4.75968 7.84444 4.81944L8.09093 4.1111L7.84444 4.81944C7.8859 4.83386 7.92658 4.85047 7.96629 4.86917C8.13077 4.94665 8.27856 5.07787 8.81228 5.6007L9.18379 5.96463L9.70863 5.42886L9.18379 5.96463C9.21249 5.99274 9.24082 6.02054 9.26881 6.04802C9.76041 6.53049 10.1494 6.91231 10.6521 7.11749C11.1548 7.32267 11.6999 7.32215 12.3887 7.3215C12.4279 7.32147 12.4676 7.32143 12.5078 7.32143H13C13.964 7.32143 14.6116 7.32302 15.0946 7.38796C15.5561 7.45001 15.7536 7.55723 15.8839 7.68754L16.4142 7.15722L15.8839 7.68755C16.0142 7.81786 16.1214 8.01536 16.1835 8.47682C16.2484 8.95986 16.25 9.60742 16.25 10.5714V12C16.25 12.964 16.2484 13.6116 16.1835 14.0946C16.1214 14.5561 16.0142 14.7536 15.8839 14.8839C15.7536 15.0142 15.5561 15.1214 15.0946 15.1835C14.6116 15.2484 13.964 15.25 13 15.25H7C6.03599 15.25 5.38843 15.2484 4.90539 15.1835C4.44393 15.1214 4.24643 15.0142 4.11612 14.8839C3.9858 14.7536 3.87858 14.5561 3.81654 14.0946C3.75159 13.6116 3.75 12.964 3.75 12V7.7282Z"
      stroke="#7B61FF"
      strokeWidth="1.5"
    />
  </svg>
);
