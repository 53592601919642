import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { NotFoundSVGImage } from '../../components/icons/not-found';
import { INotFoundProps } from './not-found.models';
import { useNotFoundStyles } from './not-found.styles';

export const NotFound: FC<INotFoundProps> = ({
  className,
  content,
  image = <NotFoundSVGImage />,
  title = 'Ошибочка вышла',
  subtitle = 'Данная страница устарела или просто не существует, перейдите на главную по ссылке ниже',
  linkText = 'На главную',
  ...other
}) => {
  const classes = useNotFoundStyles();

  return (
    <div className={cn(className, classes.root)} {...other}>
      <div className={classes.contentWrapper}>
        {content ?? (
          <div className={classes.content}>
            {image}
            <h3 className={classes.title}>
              {title}
            </h3>
            <p className={classes.subtitle}>
              {subtitle}
            </p>
            <Link
              to={'/'}
              className={classes.link}
            >
              {linkText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
