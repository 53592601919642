import { FC, SVGProps } from 'react';

export const UserCvIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      d="M23.75 17.5H30"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.75 22.5H30"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3906 22.5C16.4617 22.5 18.1406 20.8211 18.1406 18.75C18.1406 16.6789 16.4617 15 14.3906 15C12.3196 15 10.6406 16.6789 10.6406 18.75C10.6406 20.8211 12.3196 22.5 14.3906 22.5Z"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.54688 26.25C9.82225 25.1745 10.4478 24.2212 11.3248 23.5405C12.2018 22.8597 13.2804 22.4902 14.3906 22.4902C15.5008 22.4902 16.5795 22.8597 17.4565 23.5405C18.3335 24.2212 18.959 25.1745 19.2344 26.25"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.75 7.5H6.25C5.55964 7.5 5 8.05964 5 8.75V31.25C5 31.9404 5.55964 32.5 6.25 32.5H33.75C34.4404 32.5 35 31.9404 35 31.25V8.75C35 8.05964 34.4404 7.5 33.75 7.5Z"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
