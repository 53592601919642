import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { OrganizationStatus } from '../../../../../api/common.types';
import { ICreateOrganizationParams } from '../../../../../api/organization';
import { DEFAULT_FORMAT } from '../../../../../constants';
import { useAppStore } from '../../../../../stores/context.store';
import { EditIcon } from '../../../../components/icons/edit';
import { CreateOrganizationModal } from '../../../OrganizationList/components';
import { BlockOrganizationModal } from './BlockOrganizationModal';
import { useOrganizationInfoStyles } from './OrganizationInfo.styles';


export const OrganizationInfo = observer(() => {
  const { id } = useParams();
  const classes = useOrganizationInfoStyles();

  const [isOpen, setIsOpen] = useState(false);

  const { organizationStore: { organizationInfoStore } } = useAppStore();
  const { data, loading, fetchOrganization, changeOrganizationActivity } = organizationInfoStore;

  const organizationInitialValues: Partial<ICreateOrganizationParams> = useMemo(() => ({
    name: data?.name,
    address: data?.address,
    email: data?.email,
    phone: data?.phone,
  }), [data]);

  useEffect(() => {
    if (id) {
      fetchOrganization(id);
    }
  }, [id]);

  const handleOnBlock = () => {
    if (id) {
      changeOrganizationActivity({
        id,
        status: OrganizationStatus.blocked,
      });
    }
  };

  const handleOnActivate = () => {
    if (id) {
      changeOrganizationActivity({
        id,
        status: OrganizationStatus.active,
      });
    }
  };

  if (loading) {
    return (
      <Paper className={classes.paper}>
        <CircularProgress />
      </Paper>
    );
  }

  if (!data) {
    return null;
  }
  const { name, created_at, phone, email, address, status } = data;

  const createdDate = dayjs(created_at).format(DEFAULT_FORMAT);

  const openModalHandler = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.header} >
        <div title={name} className={classes.title}>{name}</div>
        <div style={{ display: 'flex', gap: 12 }} className="">
          <Button onClick={openModalHandler} size="none" style={{ background: '#EFF8FE', display: 'flex', gap: 10, padding: '8px 16px', color: '#0071CE', alignItems: 'center' }}><EditIcon />Редактировать</Button>
          <BlockOrganizationModal onConfirmBlock={handleOnBlock} onConfirmActivate={handleOnActivate} status={status} />
        </div>
      </div>
      <div className={classes.cards}>
        <div className={classes.card} title={createdDate}>
          <div className={classes.cardLabel}>Дата создания</div>
          <div className={classes.cardValue}>{createdDate}</div>
        </div>
        <div className={classes.card}>
          <div className={classes.cardLabel}>Номер телефона</div>
          <div className={classes.cardValue}>{phone}</div>
        </div>
        <div className={classes.card}>
          <div className={classes.cardLabel}>E-mail</div>
          <div className={classes.cardValue}>{email}</div>
        </div>
        <div className={classes.card} title={address}>
          <div className={classes.cardLabel}>Адрес</div>
          <div className={classes.cardValue}>{address}</div>
        </div>
      </div>
      <CreateOrganizationModal isOpen={isOpen} initialValues={organizationInitialValues} handleClose={openModalHandler} />
    </Paper>
  );
});
