export enum Role {
  Hr = 'hr',
  Supervisor = 'head',
  Employee = 'worker',
}

export enum OrganizationStatus {
  active = 'active',
  blocked = 'blocked',
}

// interface IStoreData<TData> {
//   data?: TData;
//   loading: boolean;
//   error?: string;
// }

export interface IResponseList<TData> {
  items: TData[];
  totalCount: number;
}

export interface IOneId {
  id: string;
  name: string;
  sur_name: string;
  patronymic: string;
  phone: string;
  avatar: string;
  email: string;
}

// interface IModelOption {
//   _id: string;
//   name: string;
// }

export interface IOrganizationBasic {
  _id: string;
  address: string;
  mod?: IOrganizationMod;
  email: string;
  name: string;
  phone: string;
  status: OrganizationStatus;
  created_at: string;
  updated_at: string;
}

export enum IAssesmentRoles {
  'content-manager' = 'content-manager',
  'administrator' = 'administrator',
  'organization-user' = 'organization-user',
  'tester' = 'tester',
}

// interface IAccountManager {
//   id: string,
//   createdAt: string,
//   created_at: string,
//   oneid: IOneId;
//   creator: IOneId;
// }

// export interface IOrganization {
//   _id: string;
//   name: string;
//   desc: string;
//   account_manager: IAccountManager;
//   models: IModelOption;
//   info: {
//     email: string;
//     phone: string;
//     address: string;
//   };
//   updated_at: string;
//   created_at: string;
// }

export interface IEmployeeOneid {
  id: string;
  fio: string;
  email: string;
  phone: string;
  avatar: string;
  birthday: string;
}

export interface IEmployee {
  _id: string;
  role: Role;
  oneid: IEmployeeOneid;
  organization: IOrganizationBasic;
  updated_at: string;
  created_at: string;
}

export interface IOrganizationMod {
  author_creation_id: string;
  created_at: string;
  id_auth: string;
  oneid: IEmployeeOneid;
  author: IEmployeeOneid;
}
