export enum EnvKeys {
  modelAPI = 'REACT_APP_ASSMNT_MODELS_API',
  // builderAPI = 'REACT_APP_ASSMNT_BUILDERNEO_API',
  builderAPI = 'REACT_APP_ASSMNT_BUILDERNEXT_API',
  moderationWEB = 'REACT_APP_ASSMNT_MODERATE_WEB',
  builderWEB = 'REACT_APP_ASSMNT_BUILDERNEO_WEB',
  yandexMetrikaId = 'REACT_APP_ASSMNT_YANDEX_METRIKA_ID',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  loggerAPI = 'REACT_APP_LOGGER_API',
  loggerAPIKey = 'REACT_APP_LOGGER_API_KEY',
}

export interface IConfigStore {
  modelAPI: string;
  builderAPI: string;
  localToken: string | undefined;
  yandexMetrikaId: string | undefined;
  builderWEB: string;
  moderationWEB: string;
  loggerAPI: string | undefined;
  loggerAPIKey: string | undefined;
}
