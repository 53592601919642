import { ISelectOption } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, onBecomeObserved, reaction } from 'mobx';
import { IOrganizationBasic } from '../../../api/common.types';
import { IGetOrganizationsParams, OrganizationApi } from '../../../api/organization';
import { IRootStore } from '../../../models/stores/root.store';
import { fetchAction } from '../../../shared/fetchAction';
import { IOrganizationsTableData } from './OrganizationList.models';

const DEFAULT_PARAMS = { page: 1, limit: 10, name: '' };

export class OrganizationListStore {

  public readonly rootStore: IRootStore;

  public total: number = 0;
  public error?: string;
  public loading: boolean = true;
  public organizations: IOrganizationBasic[] = [];
  public params: IGetOrganizationsParams = DEFAULT_PARAMS;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });

    onBecomeObserved(this, 'tableData', this.fetchOrganizations);

    reaction(() => this.params, this.fetchOrganizations);
  }

  public fetchOrganizations = (): void => {
    this.loading = true;

    fetchAction(() => OrganizationApi.getOrganizations(this.params), {
      successCallback: ({ items, totalCount }) => {
        this.organizations = items;
        this.total = totalCount;
      },
      errorCallback: (_, message) => {
        this.error = message;
      },
      finallyCallback: () => {
        this.loading = false;
      },
    });
  };

  public setParams = (params: Partial<IGetOrganizationsParams>): void => {
    this.params = { ...this.params, ...params };
  };

  public handleSearch = (name: string): void => {
    this.setParams({ ...DEFAULT_PARAMS, name });
  };

  public handleLimitPage = (count: ISelectOption): void => {
    if (this.params.limit !== count) {
      this.setParams({ page: 1, limit: Number(count) });
    }
  };

  public handlePrevPage = (): void => {
    if (this.params.page > 1) {
      this.setParams({ page: this.params.page - 1 });
    }
  };

  public handleNextPage = (): void => {
    if (this.params.page < this.maxPage) {
      this.setParams({ page: this.params.page + 1 });
    }
  };

  public get tableData(): IOrganizationsTableData[] {
    return this.organizations;
  }

  public get maxPage(): number {
    return Math.ceil(this.total / this.params.limit);
  }

  public reset = (): void => {
    this.organizations = [];
    this.error = undefined;
    this.loading = false;
    this.params = DEFAULT_PARAMS;
  };

}
