import { FC, SVGProps } from 'react';

export const EditIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      d="M8.96575 4.63437L11.3657 7.03437M2.96579 13.0344L5.87645 12.4479C6.03096 12.4168 6.17284 12.3407 6.28427 12.2292L12.8001 5.70982C13.1125 5.39725 13.1122 4.8906 12.7996 4.57829L11.4193 3.19957C11.1068 2.88739 10.6004 2.88761 10.2881 3.20005L3.77163 9.72008C3.66042 9.83135 3.58449 9.97294 3.55332 10.1271L2.96579 13.0344Z"
      stroke="#0071CE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
