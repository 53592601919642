/* eslint-disable no-restricted-syntax */
export const withoutEmptyProperties = <TData>(obj: TData): TData => {
  const newObj: TData = { ...obj };
  for (const key in obj) {
    if (typeof obj[key] !== 'boolean' && typeof obj[key] !== 'number' && !obj[key]) {
      delete newObj[key];
    }
  }
  return newObj;
};
