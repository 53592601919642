import {
  makeAutoObservable,
  onBecomeObserved,
  reaction,
  runInAction,
} from 'mobx';
import { IOrganizationBasic } from '../api/common.types';
import { OrganizationApi } from '../api/organization';
import { IRootStore } from '../models/stores/root.store';
import { getKyErrorMessage } from '../shared/error-message';
import { IMenuOption } from '../views/components/sidebar/sidebar.models';

export class SidebarStore {

  public readonly rootStore: IRootStore;

  public page: number = 1;
  public total: number = 0;
  public error?: string;
  public loading: boolean = true;
  private organizations: IOrganizationBasic[] = [];

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });

    onBecomeObserved(this, 'sidebarList', this.fetchOrganizations);

    reaction(() => this.page, this.fetchOrganizations);
  }

  public fetchOrganizations = async(): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true;
      });

      const { items, totalCount } = await OrganizationApi.getOrganizations({
        page: this.page,
        limit: 50,
      });

      runInAction(() => {
        this.organizations
          = this.page === 1 ? items : [...this.organizations, ...items];
        this.total = totalCount;
        this.loading = false;
      });
    }
    catch (error) {
      const errorMessage = await getKyErrorMessage(error);
      runInAction(() => {
        this.error = errorMessage;
        this.loading = false;
      });
    }
  };

  public loadNext = (): void => {
    this.page += 1;
  };

  public get sidebarList(): IMenuOption[] {
    return this.organizations.map((item) => ({
      id: item._id,
      value: item.name,
      label: item.name,
    }));
  }

}
