import { createUseStyles } from 'react-jss';

export const useNotFoundStyles = createUseStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    minHeight: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 100px',
    backgroundColor: theme.background.lightGray,
  },
  contentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '0.5rem',
    maxWidth: '24rem',
    textAlign: 'center',
  },
  title: {
    fontFamily: theme.fontFamily.monserrat,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '28px',
    color: theme.palette.gloomyBlue.main,
  },
  subtitle: {
    color: theme.palette.secondaryText.main,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    marginBottom: '1.5rem',
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.25rem',
    marginBottom: '1.5rem',
    color: theme.background.paper,
    padding: '0.5rem 1.5rem',
    borderRadius: '0.25rem',
    backgroundImage: `linear-gradient(to right, ${theme.gradient.blue.start}, ${theme.gradient.blue.end})`,
  },
}));
