import { createUseStyles } from 'react-jss';

export const CreateOrganizationModalStyles = createUseStyles(
  (theme) => ({
    root: {
      maxWidth: '600px !important',
      rowGap: '32px !important',
    },
    header: {
      fontFamily: theme.fontFamily.monserrat,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '32px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    label: {
      fontFamily: theme.fontFamily.sans,
      fontSize: 14,
      lineHeight: '20px',
      marginBottom: 4,
    },
    errorInput: {
      borderColor: 'red',
    },
    footer: {
      marginLeft: 'auto',
    },
  }),
  {
    name: 'Modal',
  },
);
