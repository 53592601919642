import { FC, SVGProps } from 'react';

export const BuildingsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 50.625H56.25"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.75 50.625V9.375C33.75 8.87772 33.5525 8.40081 33.2008 8.04918C32.8492 7.69754 32.3723 7.5 31.875 7.5H9.375C8.87772 7.5 8.40081 7.69754 8.04918 8.04918C7.69754 8.40081 7.5 8.87772 7.5 9.375V50.625"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.5 50.625V24.375C52.5 23.8777 52.3025 23.4008 51.9508 23.0492C51.5992 22.6975 51.1223 22.5 50.625 22.5H33.75"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 16.875H22.5"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 31.875H26.25"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 41.25H22.5"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.25 41.25H45"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.25 31.875H45"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
