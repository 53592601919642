import { ReactElement } from 'react';
import { Form, Field } from 'react-final-form';
import { useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
} from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Input } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/input';
import { PhoneMask } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/phone-mask';
import cn from 'classnames';
import { FormApi } from 'final-form';
import { observer } from 'mobx-react';
import { ICreateOrganizationParams } from '../../../../../api/organization';
import { REQUIRED_FIELD_ERROR_MESSAGE, States } from '../../../../../constants';
import { useAppStore } from '../../../../../stores/context.store';
import { CreateOrganizationModalStyles } from './CreateOrganizationModal.styles';

interface CreateOrganizationModalProps {
  isOpen: boolean;
  initialValues?: Partial<ICreateOrganizationParams>;
  handleClose(): void;
}

const PHONE_LENGTH_RU = 11;

export const CreateOrganizationModal: React.FC<CreateOrganizationModalProps>
  = observer(({ initialValues, isOpen, handleClose }) => {
    const { organizationStore, organizationListStore } = useAppStore();
    const { fetchOrganizations } = organizationListStore;
    const { createOrganization, organizationInfoStore } = organizationStore;
    const { id } = useParams();

    // eslint-disable-next-line no-useless-escape
    const emailValidState = /^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z,рф]{2,11}$/;

    const phoneValidState = /^7[9]\d{9}$/;

    const phoneError = 'Некорректный формат номера телефона';

    const errorText = (value: string) => (value ? undefined : REQUIRED_FIELD_ERROR_MESSAGE);
    const mustBeNumber = (value: string) => {
      const formattedPhone = value.replace(/\D/g, '');

      if (!phoneValidState.test(formattedPhone)) {
        return phoneError;
      }

      return (formattedPhone.length < PHONE_LENGTH_RU ? REQUIRED_FIELD_ERROR_MESSAGE : undefined);
    };

    const emailValid = (value: string) => (!emailValidState.test(value) ? 'Некорректный формат email' : undefined);
    // TODO typeScript any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const composeValidators = (...validators: any[]) => (value: any) => validators.reduce(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      (error, validator) => error || validator(value),
      undefined,
    );

    const resetFields = (form: FormApi<
    ICreateOrganizationParams,
    Partial<ICreateOrganizationParams>
    >) => {
      form.reset();
      form.resetFieldState('name');
      form.resetFieldState('address');
      form.resetFieldState('phone');
      form.resetFieldState('email');
    };

    const submitHandler = async(data: ICreateOrganizationParams, form: FormApi<
    ICreateOrganizationParams,
    Partial<ICreateOrganizationParams>
    >) => {
      handleClose();
      resetFields(form);
      const phone = data.phone.replace(/\D/g, '');
      const formattedData = { ...data, phone };
      await createOrganization(formattedData, id);
      if (id) {
        organizationInfoStore.fetchOrganization(id);
      }
      else {
        fetchOrganizations();
      }
    };

    const classes = CreateOrganizationModalStyles();

    const closeModal = (form: FormApi<ICreateOrganizationParams, Partial<ICreateOrganizationParams>>) => {
      resetFields(form);
      handleClose();
    };

    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        color="blue"
        className={classes.root}
        size="lg"
      >
        <ModalHeader
          className={classes.header}
          onClose={handleClose}
        >
          {id ? 'Редактировать' : 'Добавить'} организацию
        </ModalHeader>
        <Form
          initialValues={initialValues}
          onSubmit={submitHandler}
          render={({ handleSubmit, form, dirtyFields }): ReactElement => {
            const phoneIsDirty = form.getFieldState('phone')?.value?.replace(/\D/g, '') !== initialValues?.phone;
            const isDirty = Object.values({ ...dirtyFields, phone: phoneIsDirty }).some(Boolean);

            return (
              <>
                <div className={classes.form}>
                  <Field
                    name="name"
                    validate={errorText}
                    render={({
                      input,
                      meta: { error, touched },
                    }): ReactElement => (
                      <label htmlFor="name">
                        <p className={classes.label}>Название организации</p>
                        <Input
                          {...input}
                          maxLength={500}
                          id="name"
                          placeholder="Введите данные"
                          hintText={touched && error}
                          state={touched && error ? States.Error : States.Default}
                        />
                      </label>
                    )}
                  />
                  <Field
                    name="address"
                    validate={errorText}
                    render={({
                      input,
                      meta: { error, touched },
                    }): ReactElement => (
                      <label htmlFor="address">
                        <p className={classes.label}>Адрес</p>
                        <Input
                          {...input}
                          id="address"
                          maxLength={500}
                          placeholder="Введите данные"
                          hintText={touched && error}
                          state={touched && error ? States.Error : States.Default}
                        />
                      </label>
                    )}
                  />
                  <Field
                    name="phone"
                    validate={composeValidators(errorText, mustBeNumber)}
                    render={({
                      input,
                      meta: { error, touched },
                    }): ReactElement => (
                      <label htmlFor="phone">
                        <p className={classes.label}>Телефон</p>
                        <PhoneMask
                          {...input}
                          mask="+7 (999) 999-99-99"
                          placeholder="+7 (000) 000-00-00"
                          id="phone"
                          error={touched && error}
                          className={cn({ [classes.errorInput]: touched && error })}
                          state={touched && error ? States.Error : States.Default}
                        />
                      </label>
                    )}
                  />
                  <Field
                    name="email"
                    validate={composeValidators(errorText, emailValid)}
                    render={({
                      input,
                      meta: { error, touched },
                    }): ReactElement => (
                      <label htmlFor="email">
                        <p className={classes.label}>E-mail</p>
                        <Input
                          {...input}
                          id="email"
                          maxLength={320}
                          placeholder="Введите данные"
                          hintText={touched && error}
                          state={touched && error ? States.Error : States.Default}
                        />
                      </label>
                    )}
                  />
                </div>
                <ModalFooter className={classes.footer}>
                  <Button
                    size="sm"
                    variant="outlined"
                    onClick={() => closeModal(form)}
                  >
                    Отмена
                  </Button>
                  <Button
                    size="sm"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isDirty}
                  >
                    {id ? 'Сохранить' : 'Создать'}
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        />
      </Modal>
    );
  });
