import { FC, SVGProps } from 'react';

export const LoaderIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16 1.84C16 0.823796 16.8265 -0.0109305 17.836 0.105677C20.3507 0.396147 22.7698 1.28042 24.8891 2.69649C27.5203 4.45459 29.5711 6.95345 30.7821 9.87707C31.9931 12.8007 32.3099 16.0177 31.6926 19.1214C31.0752 22.2251 29.5513 25.0761 27.3137 27.3137C25.0761 29.5513 22.2251 31.0752 19.1214 31.6926C16.0177 32.3099 12.8007 31.9931 9.87706 30.7821C6.95345 29.5711 4.45459 27.5203 2.69649 24.8891C1.28042 22.7698 0.396147 20.3506 0.105677 17.836C-0.0109305 16.8265 0.823796 16 1.84 16C2.8562 16 3.66593 16.8283 3.81714 17.8332C4.08581 19.6186 4.74489 21.331 5.75629 22.8446C7.11003 24.8706 9.03415 26.4497 11.2853 27.3822C13.5365 28.3147 16.0137 28.5586 18.4035 28.0833C20.7934 27.6079 22.9886 26.4345 24.7116 24.7116C26.4345 22.9886 27.6079 20.7934 28.0833 18.4035C28.5586 16.0137 28.3147 13.5365 27.3822 11.2853C26.4497 9.03415 24.8706 7.11003 22.8446 5.75629C21.331 4.74489 19.6186 4.08581 17.8332 3.81714C16.8283 3.66593 16 2.8562 16 1.84Z" />
  </svg>
);
