import { createUseStyles } from 'react-jss';

export const useOrganizationInfoStyles = createUseStyles((theme) => ({
  paper: {
    padding: 32,
    marginBottom: 24,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  title: {
    fontSize: 28,
    maxWidth: 500,
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: theme.fontFamily.monserrat,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gloomyBlue.main,
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(120px, 1fr))',
    gridGap: 24,
  },
  card: {
    fontSize: 16,
    borderRadius: 10,
    display: 'flex',
    cursor: 'pointer',
    padding: '16px 24px',
    flexDirection: 'column',
    backgroundColor: theme.palette.gray.main,
  },
  cardLabel: {
    color: theme.palette.gray.contrastText,
  },
  cardValue: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
