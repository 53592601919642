import { FC, useContext, useEffect, useState } from 'react';
import { appTheme } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/theme';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { ChevronsIcon } from '../icons/chevrons';
import { FirmIcon } from '../icons/FirmIcon';
import { Menu } from './sidebar-menu.view';
import { ISidebarProps } from './sidebar.models';
import { useSidebarStyles } from './sidebar.styles';

export const Sidebar: FC<ISidebarProps> = observer(({ sidebarWidth, startResizing }) => {
  const appStore = useContext(appStoreContext);

  const {
    sidebarList, loading, total, loadNext,
  } = appStore.sidebar;

  const [menuActive, setMenuActive] = useState<boolean>(false);
  const classes = useSidebarStyles({ sidebarWidth, menuActive });

  const onChangeMenuActive = (): void => {
    setMenuActive(!menuActive);
  };

  // useEffect для сброса активности sidebar-a
  useEffect(() => {
    const updateWindowDimensions = (): void => {
      if (appTheme.breakpoints.lg <= window.innerHeight) {
        setMenuActive(false);
      }
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <aside className={classes.root}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Menu
            options={sidebarList}
            loadNext={loadNext}
            title="Организации"
            icon={<FirmIcon />}
            loading={loading}
            total={total}
            limit={50}
            showTotal
          />
        </div>
      </div>
      <div className={classes.switcher_wrapper}>
        <div className={classes.switcher} onClick={onChangeMenuActive}>
          <ChevronsIcon />
        </div>
      </div>
      <div className={classes.blur} onClick={onChangeMenuActive} />
      <div className={classes.resizeBlock} onMouseDown={startResizing} />
    </aside>
  );
});
