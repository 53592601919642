/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { Fragment } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/button';
import {
  Modal,
  ModalFooter,
  ModalHeader,
} from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import cn from 'classnames';
import Color from 'color';
import { OrganizationStatus } from '../../../../../api/common.types';
import { BigSituationIcon } from '../../../../components/icons/big-situation';

const useStyles = createUseStyles((theme) => ({
  status: {
    color: theme.palette.error.main,
  },
  modal: {
    padding: '32px 40px !important',
  },
  title: {
    color: theme.palette.gloomyBlue.main,
    font: {
      family: 'Montserrat',
      size: 20,
      style: 'normal',
      weight: '600',
    },
    lineHeight: '28px',
    textAlign: 'center',
  },
  activateTitle: {
    marginTop: 24,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    marginTop: 24,
  },
  buttonAction: {
    padding: '8px 20px !important',
  },
  button: {
    borderRadius: '10px !important',
  },
  buttonCancel: {
    backgroundColor: `${Color(theme.palette.error.main).alpha(0.1)} !important`,
    color: `${theme.palette.error.main} !important`,

    '&:hover': {
      backgroundColor: `${Color(theme.palette.primary.main).alpha(
        0.1,
      )} !important`,
      color: `${theme.palette.gloomyBlue.main} !important`,
    },
  },
  buttonActivateCancel: {
    backgroundColor: `${Color(theme.palette.primary.main).alpha(0.1)} !important`,
    color: `${theme.palette.gloomyBlue.main} !important`,

    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
}));

interface IBlockOrganizationModalProps {
  status: OrganizationStatus;
  onConfirmBlock(): void;
  onConfirmActivate(): void;
}

export const BlockOrganizationModal: React.FC<IBlockOrganizationModalProps> = ({
  status,
  onConfirmBlock,
  onConfirmActivate,
}) => {
  const classes = useStyles();

  const disabledModal = useModalState();
  const activateModal = useModalState();

  return (
    <Fragment>
      {status === OrganizationStatus.active && (
        <Button
          size="none"
          variant="danger"
          className={classes.buttonAction}
          onClick={disabledModal.open}
        >
          Блокировать организацию
        </Button>
      )}

      {status === OrganizationStatus.blocked && (
        <Button
          size="none"
          variant="primary"
          className={classes.buttonAction}
          onClick={activateModal.open}
        >
          Разблокировать организацию
        </Button>
      )}

      <Modal
        open={disabledModal.isOpen}
        onClose={disabledModal.close}
        color="red"
        className={classes.modal}
      >
        <ModalHeader onClose={disabledModal.close} />

        <div className={classes.content}>
          <BigSituationIcon />
          <h6 className={classes.title}>
            Вы действительно хотите заблокировать организацию?
          </h6>
        </div>

        <ModalFooter className={classes.footer}>
          <Button
            size="sm"
            variant="danger"
            onClick={onConfirmBlock}
            className={classes.button}
          >
            Заблокировать
          </Button>
          <Button
            size="sm"
            variant="white"
            onClick={disabledModal.close}
            className={cn(classes.button, classes.buttonCancel)}
          >
            Отмена
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        open={activateModal.isOpen}
        onClose={activateModal.close}
        color="blue"
        className={classes.modal}
      >
        <ModalHeader onClose={activateModal.close} />

        <div className={classes.content}>
          <h6 className={cn(classes.title, classes.activateTitle)}>
            Вы действительно хотите разблокировать организацию?
          </h6>
        </div>

        <ModalFooter className={classes.footer}>
          <Button
            size="sm"
            variant="primary"
            onClick={onConfirmActivate}
            className={classes.button}
          >
            Разблокировать
          </Button>
          <Button
            size="sm"
            variant="white"
            onClick={activateModal.close}
            className={cn(classes.button, classes.buttonActivateCancel)}
          >
            Отмена
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
