import { toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { runInAction } from 'mobx';
import {
  IAddOrganizationHrParams,
  ICreateOrganizationParams,
  IDetachOrganizationParams,
  OrganizationApi,
} from '../../../../api/organization';
import { IRootStore } from '../../../../models/stores/root.store';
import { getKyErrorMessage } from '../../../../shared/error-message';
import { fetchAction } from '../../../../shared/fetchAction';
import { EmployeeTableStore } from './EmployeeTableStore';
import { HrTableStore } from './HrTableStore';
import { OrganizationInfoStore } from './OrganizationInfoStore';

export class OrganizationStore {

  public readonly rootStore: IRootStore;
  public readonly organizationInfoStore: OrganizationInfoStore;
  public readonly hrTableStore: HrTableStore;
  public readonly EmployeeTableStore: EmployeeTableStore;

  public error?: string;
  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    this.organizationInfoStore = new OrganizationInfoStore(rootStore);
    this.hrTableStore = new HrTableStore(rootStore);
    this.EmployeeTableStore = new EmployeeTableStore(rootStore);
  }

  public createOrganization = async(
    data: ICreateOrganizationParams,
    orgId?: string,
  ): Promise<void> => {
    const succcessToastText = `Организация успешно ${orgId ? 'обновлена' : 'добавлена'}`;
    try {
      runInAction(() => {
        this.error = undefined;
        this.loading = true;
      });

      if (orgId) {
        await OrganizationApi.updateOrganization(orgId, data);
      }
      else {
        await OrganizationApi.createOrganization(data);
      }

      toast({
        type: 'success',
        text: succcessToastText,
      });

      runInAction(() => {
        this.loading = false;
      });
    }
    catch (error) {
      const errorMessage = await getKyErrorMessage(error);
      runInAction(() => {
        this.loading = false;
        this.error = errorMessage;
      });

      toast({
        type: 'error',
        text: errorMessage,
      });
    }
  };

  public addOrganizationHr = async(params: IAddOrganizationHrParams): Promise<void> => {
    await fetchAction(() => OrganizationApi.addOrganizationHr(params), {
      errorCallback: (_, message) => {
        this.error = message;
      },
      finallyCallback: () => {
        this.loading = false;
      },
    });
  };

  public detachOrganizationHr = (params: IDetachOrganizationParams): void => {
    fetchAction(() => OrganizationApi.detachOrganizationHr(params), {
      successCallback: () => {
        this.hrTableStore.fetchUsers();
      },
      errorCallback: (_, message) => {
        this.error = message;
      },
      finallyCallback: () => {
        this.loading = false;
      },
    });
  };

}
