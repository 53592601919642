import { createUseStyles } from 'react-jss';

type RuleNames = 'root' | 'container' | 'icon' | 'iconWrapper' | 'text';

interface IUseStylesProps {
  gap: number;
}

export const useStyles = createUseStyles<RuleNames, IUseStylesProps>(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.background.paper,
      borderRadius: 12,
      boxShadow: '0px 4px 8px 0px #ecedf080',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: (props): number => props.gap,
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      padding: 20,
      borderRadius: 24,
      backgroundColor: '#EFF8FE',
      color: theme.palette.primary.main,
      fontSize: 16,
    },
    text: {
      color: theme.palette.gray.contrastText,
      textAlign: 'center',
      fontFamily: theme.fontFamily.monserrat,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '28px',
    },
  }),
  { name: 'Stub' },
);
