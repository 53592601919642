import { createUseStyles } from 'react-jss';

export const useLoaderStyles = createUseStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    animation: '$spin 1s linear infinite',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 16,
    padding: 16,
    maxWidth: 320,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 18,
    lineHeight: '30px',
    textAlign: 'center',
  },
  '@keyframes spin': {
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));
