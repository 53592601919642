import { FC, SVGProps } from 'react';

export const NotFoundSVGImage: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="676"
    height="262"
    viewBox="0 0 676 262"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_6196_41084)">
      <path
        d="M223.1 191.96H186.62V239H136.06V191.96H15.42V157.08L121.02 15H175.42L77.5 149.72H137.66V107.8H186.62V149.72H223.1V191.96ZM334.605 242.84C316.045 242.84 299.512 238.253 285.005 229.08C270.498 219.907 259.085 206.68 250.765 189.4C242.658 171.907 238.605 151.107 238.605 127C238.605 102.893 242.658 82.2 250.765 64.92C259.085 47.4267 270.498 34.0933 285.005 24.92C299.512 15.7467 316.045 11.16 334.605 11.16C353.165 11.16 369.698 15.7467 384.205 24.92C398.712 34.0933 410.018 47.4267 418.125 64.92C426.445 82.2 430.605 102.893 430.605 127C430.605 151.107 426.445 171.907 418.125 189.4C410.018 206.68 398.712 219.907 384.205 229.08C369.698 238.253 353.165 242.84 334.605 242.84ZM334.605 199C348.258 199 358.925 193.133 366.605 181.4C374.498 169.667 378.445 151.533 378.445 127C378.445 102.467 374.498 84.3333 366.605 72.6C358.925 60.8667 348.258 55 334.605 55C321.165 55 310.498 60.8667 302.605 72.6C294.925 84.3333 291.085 102.467 291.085 127C291.085 151.533 294.925 169.667 302.605 181.4C310.498 193.133 321.165 199 334.605 199ZM660.913 191.96H624.433V239H573.873V191.96H453.233V157.08L558.833 15H613.233L515.313 149.72H575.473V107.8H624.433V149.72H660.913V191.96Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6196_41084"
        x="0.419922"
        y="0.160034"
        width="675.493"
        height="261.68"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6196_41084"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6196_41084"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
