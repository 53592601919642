/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/button';
import {
  Modal,
  ModalFooter,
  ModalHeader,
} from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal';
import { IModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import cn from 'classnames';
import Color from 'color';
import { BigSituationIcon } from '../../../../../../components/icons/big-situation';

const useStyles = createUseStyles((theme) => ({
  status: {
    color: theme.palette.error.main,
  },
  modal: {
    padding: '32px 40px !important',
  },
  title: {
    color: theme.palette.gloomyBlue.main,
    font: {
      family: 'Montserrat',
      size: 20,
      style: 'normal',
      weight: '600',
    },
    lineHeight: '28px',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    marginTop: 24,
  },
  button: {
    borderRadius: '10px !important',
  },
  buttonCancel: {
    backgroundColor: `${Color(theme.palette.error.main).alpha(0.1)} !important`,
    color: `${theme.palette.error.main} !important`,

    '&:hover': {
      backgroundColor: `${Color(theme.palette.primary.main).alpha(
        0.1,
      )} !important`,
      color: `${theme.palette.gloomyBlue.main} !important`,
    },
  },
}));

interface IDetachModeratorModalProps {
  onConfirm(): void;
  modalState: IModalState<string | undefined>;
}

export const DetachModeratorModal: React.FC<IDetachModeratorModalProps> = ({
  modalState,
  onConfirm,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={modalState.isOpen}
      onClose={modalState.close}
      color="red"
      className={classes.modal}
    >
      <ModalHeader onClose={modalState.close} />

      <div className={classes.content}>
        <BigSituationIcon />
        <h6 className={classes.title}>
          Вы действительно хотите удалить модератора организации?
        </h6>
      </div>

      <ModalFooter className={classes.footer}>
        <Button
          size="sm"
          variant="danger"
          onClick={onConfirm}
          className={classes.button}
        >
          Удалить
        </Button>
        <Button
          size="sm"
          variant="white"
          onClick={modalState.close}
          className={cn(classes.button, classes.buttonCancel)}
        >
          Отмена
        </Button>
      </ModalFooter>
    </Modal>
  );
};
