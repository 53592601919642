import { createUseStyles } from 'react-jss';

export const MIN_CONTENT_WRAPPER_WIDTH = 1024;
export const MIN_SIDEBAR_WIDTH = 340;

export const useMainStyles = createUseStyles((theme) => ({
  main: {
    display: 'flex',
    minHeight: 'calc(100vh - 64px)',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    minHeight: 'calc(100vh - 64px)',
    background: theme.background.lightGray,
  },
  page: {
    flexGrow: 1,
    width: '100%',
  },
  content: {
    padding: '24px 48px',
    minWidth: MIN_CONTENT_WRAPPER_WIDTH,
    minHeight: 'calc(100vh - 106px)',
    display: 'flex',
    flexDirection: 'column',
  },
  option: {
    display: 'inline-block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    '&:hover': {
      textOverflow: 'initial',
      whiteSpace: 'normal',
    },
  },

  [`@media (max-width: ${MIN_SIDEBAR_WIDTH + MIN_CONTENT_WRAPPER_WIDTH}px)`]: {
    content: {
      minWidth: `calc(100vw - ${MIN_SIDEBAR_WIDTH}px)`,
    },
  },

  '@media(max-width: 1024px)': {
    option: {
      whiteSpace: 'nowrap',
    },
    content: {
      padding: {
        left: 48,
        right: 38,
        top: 36,
        bottom: 36,
      },
    },
  },

  [`@media(max-width: ${theme.breakpoints.lg}px)`]: {
    content: {
      padding: {
        left: 24,
        right: 14,
        top: 36,
        bottom: 36,
      },
    },
  },

  [`@media(max-width: ${theme.breakpoints.md}px)`]: {
    content: {
      padding: {
        left: 12,
        right: 2,
        top: 24,
        bottom: 24,
      },
    },
  },
}));
