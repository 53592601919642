import ky from 'ky';
import { getCookie } from '../shared/get-cookie';

export const projectApi = ky.create({
  prefixUrl: `${process.env.REACT_APP_360_API}/api`,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', getCookie('_utid') || process.env.REACT_APP_LOCAL_TOKEN || '');
      },
    ],
  },
});
