import { projectApi } from '../baseConfig';
import {
  IEmployee,
  IOrganizationBasic,
  IResponseList,
} from '../common.types';
import { getSearchParams } from '../utils';
import {
  IUserIdRes,
  ICreateOrganizationParams,
  IGetOrganizationsParams,
  IGetEmployeeListParams,
  IAddOrganizationHrParams,
  IOrganizationActivityParams,
  IDetachOrganizationParams,
  IChangeOrganizationModerator,
} from './organizationApi.types';

export const getOrganization = (id: string) => projectApi.get(`v1/organization/${id}`).json<IOrganizationBasic>();

export const createOrganization = (data: ICreateOrganizationParams) => projectApi
  .post('v1/organization', {
    json: data,
  })
  .json<IOrganizationBasic>();

export const updateOrganization = (id: string, json: ICreateOrganizationParams) => projectApi
  .put(`v1/organization/${id}/info`, { json })
  .json<IOrganizationBasic>();

export const getOrganizations = (params: IGetOrganizationsParams) => {
  const searchParams = getSearchParams(params);

  return projectApi
    .get('v1/organizations', {
      searchParams,
    })
    .json<IResponseList<IOrganizationBasic>>();
};

export const addOrganizationHr = ({ organizationId, id }: IAddOrganizationHrParams) => projectApi
  .post(`v1/organizations/${organizationId}/hr`, {
    json: { id_auth: id },
  })
  .json<IUserIdRes>();

export const detachOrganizationEmployee = ({ id, organizationId }: IDetachOrganizationParams) => projectApi
  .patch(`v1/organizations/${organizationId}/detach-employee`, {
    json: { id_auth: id },
  })
  .json<IUserIdRes>();

export const detachOrganizationHr = ({ id, organizationId }: IDetachOrganizationParams) => projectApi
  .patch(`v1/organizations/${organizationId}/detach-hr`, {
    json: { id_auth: id },
  })
  .json<IUserIdRes>();

export const getEmployeeList = ({ id, ...params }: IGetEmployeeListParams) => {
  const searchParams = getSearchParams(params);

  return projectApi
    .get(`v1/organizations/${id}/list-employee`, {
      searchParams,
    })
    .json<IResponseList<IEmployee>>();
};

export const organizationActivity = ({ id, status }: IOrganizationActivityParams) => projectApi
  .patch(`v1/organization/${id}/activity`, {
    json: { status },
  })
  .json<IOrganizationBasic>();

export const asignOrganizationModerator = (json: IChangeOrganizationModerator) => projectApi
  .post('v1/mod/assign', { json })
  .json<IOrganizationBasic>();

export const detachOrganizationModerator = (json: IChangeOrganizationModerator) => projectApi
  .post('v1/mod/detach', { json })
  .json<IOrganizationBasic>();
