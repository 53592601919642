import { FC, Fragment, PropsWithChildren, useContext, useEffect } from 'react';
import { Landing } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { Loader } from '../../components/loader';
import { WithFooter } from '../../components/with-footer';
import { useAccessStyles } from './access.styles';

export const Access: FC<PropsWithChildren> = observer((props) => {
  const appStore = useContext(appStoreContext);

  const classes = useAccessStyles();

  useEffect(() => {
    appStore.role.getBuilderUser();
  }, []);

  const iconSize = 48;

  if (appStore.role.loading) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.block}>
          <Loader width={iconSize} height={iconSize} />
          <span>Выполняется проверка авторизации</span>
        </div>
      </div>
    );
  }

  if (appStore.role.data?.role !== 'content-manager') {
    return (
      <WithFooter>
        <Landing />
      </WithFooter>
    );
  }

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
});
