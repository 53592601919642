import { IRootStore } from '../models/stores/root.store';
import { OrganizationStore } from '../views/content/Organization/store';
import { OrganizationListStore } from '../views/content/OrganizationList/OrganizationListStore';
import { ConfigStore } from './config.store';
import { OneIdStore } from './oneIdUsers.store';
import { RoleStore } from './role.store';
import { SidebarStore } from './sidebarStore';
import { UserStore } from './user.auth.store';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly user: UserStore;
  public readonly role: RoleStore;
  public readonly sidebar: SidebarStore;
  public readonly organizationStore: OrganizationStore;
  public readonly organizationListStore: OrganizationListStore;
  public readonly OneIdStore: OneIdStore;

  constructor() {
    this.config = new ConfigStore();
    this.user = new UserStore(this);
    this.role = new RoleStore(this);
    this.sidebar = new SidebarStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.organizationListStore = new OrganizationListStore(this);
    this.OneIdStore = new OneIdStore(this);
  }

}
