import { FC, SVGProps } from 'react';

export const UserStarIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      d="M16.875 25C22.0527 25 26.25 20.8027 26.25 15.625C26.25 10.4473 22.0527 6.25 16.875 6.25C11.6973 6.25 7.5 10.4473 7.5 15.625C7.5 20.8027 11.6973 25 16.875 25Z"
      stroke="#0071CE"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M3.46875 31.2501C5.11134 29.2923 7.16276 27.7181 9.47885 26.6381C11.7949 25.558 14.3195 24.9983 16.875 24.9983C19.4305 24.9983 21.9551 25.558 24.2712 26.6381C26.5872 27.7181 28.6387 29.2923 30.2813 31.2501"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.375 23.75C35.7557 23.75 36.875 22.6307 36.875 21.25C36.875 19.8693 35.7557 18.75 34.375 18.75C32.9943 18.75 31.875 19.8693 31.875 21.25C31.875 22.6307 32.9943 23.75 34.375 23.75Z"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.375 18.75V16.875"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.2031 20L30.5938 19.0625"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.2031 22.5L30.5938 23.4375"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.375 23.75V25.625"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.5469 22.5L38.1563 23.4375"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.5469 20L38.1563 19.0625"
      stroke="#0071CE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
