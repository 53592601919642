import { useState, useCallback, HTMLAttributes, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Table } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { DEFAULT_FORMAT_WITH_TIME } from '../../../../../constants';
import { useAppStore } from '../../../../../stores/context.store';
import { EditIcon } from '../../../../components/icons/edit';
import { PlusIcon } from '../../../../components/icons/plus';
import { TrashIcon } from '../../../../components/icons/trash';
import { UserStarIcon } from '../../../../components/icons/user-star';
import { Stub } from '../../../../components/stub';
import { AddModeratorModal } from './components/AddModeratorModal/AddModeratorModal';
import { DetachModeratorModal } from './components/DetachModeratorModal/DetachModeratorModal';
import { IModeratorTableColumn, IModeratorTableData } from './ModeratorTable.models';
import { useModeratorTableStyles } from './ModeratorTable.styles';

const COLUMNS: IModeratorTableColumn[] = [
  {
    name: 'Пользователь',
    key: 'user',
  },
  {
    name: 'E-mail',
    key: 'email',
  },
  {
    name: 'Номер телефона',
    key: 'phone',
  },
  {
    name: 'Дата добавления',
    key: 'createdAt',
  },
  {
    name: 'Кто добавил',
    key: 'author',
  },
  {
    name: '',
    key: 'deleteBtn',
  },
];

export const ModeratorTable = observer(() => {
  const { id: organizationId } = useParams();
  const classes = useModeratorTableStyles();

  const { organizationStore } = useAppStore();
  const { organizationInfoStore } = organizationStore;
  const { removeOrganizationModerator, changeModeratorLoading, ModeratorTableData } = organizationInfoStore;
  const [addModeratorModal, setAddModeratorModal] = useState(false);

  const detachEmployeeModal = useModalState<string | undefined>();

  const handleCloseModal = () => {
    setAddModeratorModal(false);
  };

  const handleOpenConfirmDetach = (id: string) => {
    detachEmployeeModal.open(id);
  };

  const handleDetachModerator = () => {
    if (organizationId && detachEmployeeModal.data) {
      removeOrganizationModerator({
        organization_id: organizationId,
        id_auth: detachEmployeeModal.data,
      });

      detachEmployeeModal.close();
    }
  };

  const rowRender = useCallback(
    (
      row: IModeratorTableData,
      rowProps: HTMLAttributes<HTMLDivElement>,
    ): ReactElement => (
      <div className={cn(rowProps.className, classes.row)}>
        <div className={cn(classes.tableBodyRow, classes.tableRowOrgName)}>
          {row.oneid?.fio}
        </div>
        <div className={classes.tableBodyRow}>{row.oneid?.email}</div>
        <div className={classes.tableBodyRow}>{row.oneid?.phone}</div>
        <div className={classes.tableBodyRow}>{dayjs(row.created_at).format(DEFAULT_FORMAT_WITH_TIME)}</div>
        <div className={classes.tableBodyRow}>{row.author?.email}</div>
        <div className={classes.deleteModeratorBtn} onClick={() => handleOpenConfirmDetach(row.id_auth)}>
          <TrashIcon />
        </div>
      </div>
    ),
    [],
  );

  const headerRowRender = ({ name, key }: IModeratorTableColumn): ReactElement => {
    if (key === 'deleteBtn') {
      return <div className={classes.deleteBtnColumn}></div>;
    }

    return (
      <div
        className={cn(classes.tableHeaderRow, {
          [classes.tableHeaderOrgName]: key === 'user',
        })}
      >
        <div>{name}</div>
      </div>
    );
  };

  const hasNoModerator = !ModeratorTableData?.[0].oneid;

  return (
    <Paper className={classes.root}>
      <div className={classes.tableHeader}>
        <div className={classes.tableSearch}>
          <div className={classes.title}>Модератор</div>
        </div>
        <div>
          <Button
            size="none"
            className={cn(classes.addBtn, { [classes.changeModeratorBtn]: !hasNoModerator })}
            onClick={() => setAddModeratorModal(true)}
          >
            {hasNoModerator ? <PlusIcon /> : <EditIcon />}
            {hasNoModerator ? 'Добавить' : 'Изменить'} модератора
          </Button>
        </div>
      </div>
      {hasNoModerator ? (
        <Stub
          className={classes.emptyRoot}
          textClassName={classes.emptyText}
          text="Здесь будет отображаться модератор организации"
          icon={<UserStarIcon width={40} height={40} />}
        />
      ) : (
        <Table
          columns={COLUMNS}
          data={ModeratorTableData}
          className={classes.table}
          headerRowRender={headerRowRender}
          rowRender={rowRender}
          loading={changeModeratorLoading}
        />
      )}
      <AddModeratorModal current={ModeratorTableData?.[0].oneid?.id} isOpen={addModeratorModal} handleClose={handleCloseModal} />
      <DetachModeratorModal onConfirm={handleDetachModerator} modalState={detachEmployeeModal} />
    </Paper>
  );
});
