import { Outlet, RouteObject } from 'react-router-dom';
import { BrowserRoute } from '../../routes/browser.routes';
import { Main } from '../components/main';
import { Access } from '../content/access';
import { Auth } from '../content/auth/auth.view';
import { NotFound } from '../content/not-found';
import { Organization } from '../content/Organization';
import { OrganizationList } from '../content/OrganizationList';

export const appRoutesDefinition: RouteObject[] = [{
  path: '/',
  element: (
    <Auth>
      <Access>
        <Outlet />
      </Access>
    </Auth>
  ),
  children: [
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: BrowserRoute.index,
      element: (
        <Main>
          <OrganizationList />
        </Main>
      ),
    },
    {
      path: BrowserRoute.organization,
      element: (
        <Main>
          <Organization />
        </Main>
      ),
    },
  ],
}];
