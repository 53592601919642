import { FC, SVGProps } from 'react';

export const UserCirclePlusIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 25C23.4518 25 26.25 22.2018 26.25 18.75C26.25 15.2982 23.4518 12.5 20 12.5C16.5482 12.5 13.75 15.2982 13.75 18.75C13.75 22.2018 16.5482 25 20 25Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M9.96875 31.1562C10.9092 29.3038 12.3443 27.7479 14.1149 26.6611C15.8855 25.5743 17.9225 24.999 20 24.999C22.0775 24.999 24.1145 25.5743 25.8851 26.6611C27.6557 27.7479 29.0908 29.3038 30.0312 31.1562"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 8.75H35"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.25 5V12.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.8125 17.6407C34.9389 18.4207 35.0016 19.2098 35 20C35 22.9667 34.1203 25.8668 32.4721 28.3336C30.8238 30.8003 28.4812 32.7229 25.7403 33.8582C22.9994 34.9935 19.9834 35.2906 17.0737 34.7118C14.1639 34.133 11.4912 32.7044 9.39341 30.6066C7.29562 28.5088 5.86701 25.8361 5.28823 22.9264C4.70945 20.0167 5.0065 17.0007 6.14181 14.2598C7.27713 11.5189 9.19972 9.1762 11.6665 7.52798C14.1332 5.87976 17.0333 5.00003 20 5.00003C20.7902 4.99848 21.5793 5.06118 22.3594 5.18753"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
