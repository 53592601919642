import { ISelectOption } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { makeAutoObservable, onBecomeObserved, reaction } from 'mobx';
import { IEmployee, Role } from '../../../../api/common.types';
import {
  IGetEmployeeListParams,
  OrganizationApi,
} from '../../../../api/organization';
import { IRootStore } from '../../../../models/stores/root.store';
import { fetchAction } from '../../../../shared/fetchAction';
import { IHrTableData } from '../components/HrTable/HrTable.models';

interface IUsersParams
  extends Omit<IGetEmployeeListParams, 'role' | 'id' | 'page' | 'limit'> {
  id?: string;
  page: number;
  limit: number;
}

const DEFAULT_PARAMS = { page: 1, limit: 10, name: '' };

export class EmployeeTableStore {

  public readonly rootStore: IRootStore;

  public total: number = 0;
  public error?: string;
  public loading: boolean = true;
  public users: IEmployee[] = [];
  public params: IUsersParams = DEFAULT_PARAMS;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });

    onBecomeObserved(this, 'tableData', this.fetchUsers);

    reaction(() => this.params, this.fetchUsers);
  }

  public fetchUsers = (): void => {
    if (!this.params.id) {
      return;
    }

    this.loading = true;
    this.error = undefined;

    fetchAction(
      () => OrganizationApi.getEmployeeList({
        ...this.params,
        role: `${Role.Employee},${Role.Supervisor}`,
      } as IGetEmployeeListParams),
      {
        successCallback: ({ items, totalCount }) => {
          this.users = items;
          this.total = totalCount;
        },
        errorCallback: (_, message) => {
          this.error = message;
        },
        finallyCallback: () => {
          this.loading = false;
        },
      },
    );
  };

  public setParams = (params: Partial<IUsersParams>): void => {
    this.params = { ...this.params, ...params };
  };

  public setEmail = (email: string): void => {
    this.params = {
      ...this.params,
      email,
    };
  };

  public setName = (name: string): void => {
    this.params = {
      ...this.params,
      name,
    };
  };

  public setId = (id: string): void => {
    this.params = {
      ...this.params,
      id,
    };
  };

  public handleSearch = (name: string): void => {
    this.setParams({ ...DEFAULT_PARAMS, name });
  };

  public handleLimitPage = (count: ISelectOption): void => {
    if (this.params.limit !== count) {
      this.setParams({ page: 1, limit: Number(count) });
    }
  };

  public handlePrevPage = (): void => {
    if (this.params.page > 1) {
      this.setParams({ page: this.params.page - 1 });
    }
  };

  public handleNextPage = (): void => {
    if (this.params.page < this.maxPage) {
      this.setParams({ page: this.params.page + 1 });
    }
  };

  public get tableData(): IHrTableData[] {
    return this.users;
  }

  public get maxPage(): number {
    return Math.ceil(this.total / this.params.limit);
  }

}
