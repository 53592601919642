import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  modal: {
    width: '100%',
    maxHeight: 'min(750px, 100%)',
    maxWidth: '640px !important',
    margin: 32,
    '& $row': {
      wordBreak: 'break-word',
      '&:hover': {
        background: '#eff8fe80',
      },
    },
  },
  title: {
    color: 'var(--Grey-70, #3B4168)',
    font: {
      family: 'Montserrat',
      style: 'normal',
      weight: 700,
      size: 24,
    },
  },
  label: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },
  labelText: {
    color: '#3B4168',
  },
  input: {
    border: {
      style: 'solid !important',
      width: '1 !important',
      radius: '10 !important',
    },
    background: '#fff',
    boxShadow: '0px 4px 10px 0px rgba(0, 113, 206, 0.08)',

    '&:focus': {
      borderColor: '#0071CE !important',
    },
  },
  headerRow: {
    padding: ['3px', '6px'],
    display: 'grid !important',
    gridTemplateColumns: '42px 1fr 1fr',
    borderBottom: `1px solid ${theme.palette.lines.main}`,
  },
  row: {
    padding: ['3px', '6px'],
    display: 'grid !important',
    gridTemplateColumns: '42px 1fr 1fr',
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  checkboxColumn: {
    padding: 12,
  },
  column: {
    padding: ['10px', '16px'],
  },
  checked: {
    backgroundColor: '#EFF8FE !important',
  },
  table: {
    overflow: 'auto',
    border: `1px solid ${theme.palette.lines.main}`,
    borderRadius: 12,
  },
  tableWrapper: {
    overflow: 'auto',
    marginRight: -16,
    paddingRight: 12,
  },
  footer: {
    marginLeft: 'auto',
  },
}));
