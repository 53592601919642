import { createUseStyles } from 'react-jss';

export const useModeratorTableStyles = createUseStyles((theme) => ({
  root: {
    padding: {
      top: 20,
      right: 40,
      bottom: 42,
      left: 40,
    },
    marginBottom: 24,
  },
  title: {
    fontSize: 28,
    marginRight: 20,
    marginBottom: 16,
    fontFamily: theme.fontFamily.monserrat,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gloomyBlue.main,
  },
  tableHeader: {
    display: 'flex',
    marginBottom: 24,
    justifyContent: 'space-between',
  },
  tableSearch: {
    display: 'flex',
  },
  searchField: {
    padding: '6px 12px !important',
    borderRadius: '8px !important',
    lineHeight: '20px !important',
    fontWeight: 400,
  },
  addBtn: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    padding: '8px 16px !important',
  },
  changeModeratorBtn: {
    backgroundColor: '#EFF8FE !important',
    color: `${theme.palette.primary.main} !important`,
  },
  deleteModeratorBtn: {
    width: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: theme.palette.error.main,
      background: '#FEF2F2',
    },
  },
  deleteBtnColumn: {
    width: 30,
    borderBottom: '1px solid #E4E7F2',
  },
  table: {
    borderRadius: 10,
    border: '1px solid #E4E7F2',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  tableHeaderRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flex: 1,
    minWidth: 200,
    padding: ['16px', '11px'],
    borderBottomWidth: 1,
    fontFamily: theme.fontFamily.sans,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
  },
  tableHeaderOrgName: {
    flex: 2,
  },
  tableBodyRow: {
    flex: 1,
    minWidth: 200,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    padding: ['16px', '12px'],
  },
  tableRowOrgName: {
    flex: 2,
  },
  emptyRoot: {
    padding: '48px 0',
  },
  emptyText: {
    fontSize: 18,
    lineHeight: '26px',
  },
  row: {
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  extraRow: {
    cursor: 'default !important',
    '&:hover': {
      background: 'initial !important',
    },
  },
}));
