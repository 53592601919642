import {
  ChangeEvent,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  Table,
} from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Checkbox } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/checkbox';
import { Input } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/input';
import useDebounceFn from 'ahooks/lib/useDebounceFn';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { States } from '../../../../../../../constants';
import { useIntersect } from '../../../../../../../shared/use-intersect';
import { useAppStore } from '../../../../../../../stores/context.store';
import { IHrModalTableColumn, IHrModalTableData } from './AddHrModal.models';
import { useStyles } from './AddHrModal.styles';

const COLUMNS: IHrModalTableColumn[] = [
  {
    name: '',
    key: 'checkbox',
  },
  {
    name: 'Пользователь',
    key: 'user',
  },
  {
    name: 'E-mail',
    key: 'email',
  },
];

interface IAddHrModalProps {
  isOpen: boolean;
  handleClose(): void;
}

export const AddHrModal: React.FC<IAddHrModalProps> = observer(
  ({ isOpen, handleClose }) => {
    const { id: organizationId } = useParams();
    const classes = useStyles();
    const { OneIdStore, organizationStore } = useAppStore();
    const { error, setEmail, setPage, tableData, loading, reset, params, total } = OneIdStore;
    const { addOrganizationHr, hrTableStore } = organizationStore;
    const [addIsLoading, setAddIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const lastItemRef = useRef(null);
    const { inView } = useIntersect(lastItemRef);
    const isMaxPage = params.page * params.limit >= total;

    useEffect(() => {
      if (!isOpen) {
        setAddIsLoading(false);
        setIdList([]);
        setSearch('');
        return;
      }
      reset();
    }, [isOpen]);

    useEffect(() => {
      if (!loading && inView && !isMaxPage) {
        setPage(params.page + 1);
      }
    }, [inView]);


    const [idList, setIdList] = useState<string[]>([]);

    const allSelected = useMemo(() => {
      const list = tableData.map((item) => item._id).sort();
      const selectedList = idList.sort();
      const checked
        = selectedList.length === list.length &&
        selectedList.every((value, index) => value === list[index]);

      return checked;
    }, [idList, tableData]);

    const handleToggleId = (id: string) => {
      if (idList.length === 0) {
        setIdList([id]);

        return;
      }

      if (idList.indexOf(id) === -1) {
        setIdList((prevItems) => [...prevItems, id]);
      }
      else {
        setIdList((prevItems) => prevItems.filter((item) => item !== id));
      }
    };

    const handleToggleAllHrs = () => {
      if (allSelected) {
        setIdList([]);

        return;
      }

      const list = tableData.map((item) => item._id);

      setIdList((prevItems) => {
        const newArr = [...prevItems, ...list];

        return newArr.filter((item, pos) => newArr.indexOf(item) === pos);
      });
    };

    const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
      const email = event.target.value;
      setSearch(email);
      run(email);
    };

    const handleAddHrs = async() => {
      if (!organizationId) {
        return;
      }
      setAddIsLoading(true);
      for (const id of idList) {
        await addOrganizationHr({
          organizationId,
          id,
        });
      }
      if (idList.length) {
        hrTableStore.setId(organizationId);
      }
      handleClose();
    };

    const { run } = useDebounceFn(
      (email: string) => setEmail(email),
      { wait: 500 },
    );

    const headerRowRender = ({
      name,
      key,
    }: IHrModalTableColumn): ReactElement => {
      if (key === 'checkbox') {
        return (
          <div className={classes.checkboxColumn}>
            <Checkbox
              name="select-all"
              checked={allSelected}
              onClick={handleToggleAllHrs}
              readOnly
            />
          </div>
        );
      }

      return <div className={cn(classes.column)}>{name}</div>;
    };

    const rowRender = useCallback(
      (
        { _id: id, name, sur_name, patronymic, email }: IHrModalTableData,
        rowProps: HTMLAttributes<HTMLDivElement>,
      ): ReactElement => {
        const checked = idList.some((hr) => hr === id);
        return (
          <div className={cn(rowProps.className, classes.row, { [classes.checked]: checked })} onClick={(): void => handleToggleId(id)} key={id}>
            <div className={classes.checkboxColumn}>
              <Checkbox
                name={id}
                checked={checked}
                readOnly
              />
            </div>
            <div className={cn(classes.column)}>{sur_name} {name} {patronymic}</div>
            <div className={cn(classes.column)}>{email}</div>
          </div>
        );
      },
      [idList],
    );

    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        color="blue"
        className={classes.modal}
        size="lg"
      >
        <ModalHeader onClose={handleClose}>
          <h5 className={classes.title}>Добавить HR</h5>
        </ModalHeader>

        <label htmlFor="name" className={classes.label}>
          <p className={classes.labelText}>E-mail пользователя</p>
          <Input
            value={search}
            id="name"
            placeholder="Введите e-mail"
            hintText={error}
            state={error ? States.Error : States.Default}
            className={classes.input}
            onChange={handleOnSearch}
            disabled={addIsLoading}
          />
        </label>

        <div className={cn(classes.tableWrapper, 'scrollbar')}>
          <Table
            columns={COLUMNS}
            data={tableData}
            loading={loading || addIsLoading}
            className={cn(classes.table)}
            headerRowRender={headerRowRender}
            rowRender={rowRender}
            headerClassName={classes.headerRow}
          />
          <div ref={lastItemRef} />
        </div>

        <ModalFooter className={classes.footer}>
          <Button disabled={addIsLoading} size="sm" variant="outlined" onClick={handleClose}>
            Отмена
          </Button>
          <Button disabled={addIsLoading || !idList.length} size="sm" type="submit" onClick={handleAddHrs}>
            Добавить
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
