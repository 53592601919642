import {
  ChangeEvent,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  Table,
} from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Checkbox } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/checkbox';
import { Input } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/input';
import useDebounceFn from 'ahooks/lib/useDebounceFn';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { States } from '../../../../../../../constants';
import { useIntersect } from '../../../../../../../shared/use-intersect';
import { useAppStore } from '../../../../../../../stores/context.store';
import { IModeratorModalTableColumn, IModeratorModalTableData } from './AddModeratorModal.models';
import { useStyles } from './AddModeratorModal.styles';

const COLUMNS: IModeratorModalTableColumn[] = [
  {
    name: '',
    key: 'checkbox',
  },
  {
    name: 'Пользователь',
    key: 'user',
  },
  {
    name: 'E-mail',
    key: 'email',
  },
];

interface IAddModeratorModalProps {
  isOpen: boolean;
  handleClose(): void;
  current?: string;
}

export const AddModeratorModal: React.FC<IAddModeratorModalProps> = observer(
  ({ isOpen, handleClose, current }) => {
    const { id: organizationId } = useParams();
    const classes = useStyles();
    const { organizationStore: { organizationInfoStore } } = useAppStore();
    const {
      changeModeratorListParams,
      moderatorListParams,
      moderatorListLoading,
      moderatorListTotal,
      moderatorList,
      changeOrganizationModerator,
      changeModeratorLoading,
    } = organizationInfoStore;
    const [moderatorId, setModeratorId] = useState<string | undefined>(current);
    const [search, setSearch] = useState('');
    const lastItemRef = useRef(null);
    const { inView } = useIntersect(lastItemRef);
    const isMaxPage = moderatorListParams.page * moderatorListParams.limit >= moderatorListTotal;

    useEffect(() => {
      if (!isOpen) {
        changeModeratorListParams({});
        setSearch('');
        return;
      }
      setModeratorId(current);
    }, [isOpen]);

    useEffect(() => {
      if (!moderatorListLoading && inView && !isMaxPage) {
        changeModeratorListParams({ ...moderatorListParams, page: moderatorListParams.page + 1 });
      }
    }, [inView]);

    const handleToggleId = (id: string) => {
      setModeratorId(id);
    };

    const tableData = useMemo(() => moderatorList.map((el) => ({ ...el, _id: el.oneid.id })), [moderatorList]);

    const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
      const email = event.target.value;
      setSearch(email);
      run(email);
    };

    const handleChangeModerator = async() => {
      if (!organizationId || !moderatorId) {
        return;
      }
      await changeOrganizationModerator({ id_auth: moderatorId, organization_id: organizationId });
      handleClose();
    };

    const { run } = useDebounceFn(
      (email: string) => changeModeratorListParams({ email }),
      { wait: 500 },
    );

    const headerRowRender = ({
      name,
      key,
    }: IModeratorModalTableColumn): ReactElement => {
      if (key === 'checkbox') {
        return (
          <div className={classes.checkboxColumn} />
        );
      }

      return <div className={classes.column}>{name}</div>;
    };

    const rowRender = useCallback(
      (
        { oneid: { fio, email, id } }: IModeratorModalTableData,
        rowProps: HTMLAttributes<HTMLDivElement>,
      ): ReactElement => {
        const checked = moderatorId === id;
        return (
          <div className={cn(rowProps.className, classes.row, { [classes.checked]: checked })} onClick={(): void => handleToggleId(id)} key={id}>
            <div className={classes.checkboxColumn}>
              <Checkbox
                name={id}
                checked={checked}
                readOnly
              />
            </div>
            <div className={classes.column}>{fio}</div>
            <div className={classes.column}>{email}</div>
          </div>
        );
      },
      [moderatorId],
    );

    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        color="blue"
        className={classes.modal}
        size="lg"
      >
        <ModalHeader onClose={handleClose}>
          <h5 className={classes.title}>{current ? 'Изменить' : 'Добавить'} модератора</h5>
        </ModalHeader>

        <label htmlFor="name" className={classes.label}>
          <p className={classes.labelText}>E-mail пользователя</p>
          <Input
            id="name"
            placeholder="Введите e-mail"
            state={States.Default}
            className={classes.input}
            onChange={handleOnSearch}
            disabled={changeModeratorLoading}
            value={search}
          />
        </label>

        <div className={cn(classes.tableWrapper, 'scrollbar')}>
          <Table
            columns={COLUMNS}
            data={tableData}
            loading={moderatorListLoading || changeModeratorLoading}
            className={classes.table}
            headerRowRender={headerRowRender}
            rowRender={rowRender}
            headerClassName={classes.headerRow}
          />
          <div ref={lastItemRef} />
        </div>

        <ModalFooter className={classes.footer}>
          <Button disabled={changeModeratorLoading} size="sm" variant="outlined" onClick={handleClose}>
            Отмена
          </Button>
          <Button disabled={moderatorId === current || !moderatorId || changeModeratorLoading} size="sm" type="submit" onClick={handleChangeModerator}>
            {current ? 'Изменить' : 'Добавить'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
