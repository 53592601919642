import { makeAutoObservable, reaction } from 'mobx';
import { IAssesmentRoles, IOrganizationBasic } from '../../../../api/common.types';
import { IChangeOrganizationModerator, IOrganizationActivityParams, OrganizationApi } from '../../../../api/organization';
import { IAssesmentUser, IGetAssesmentUserParams, userApi } from '../../../../api/userApi';
import { IRootStore } from '../../../../models/stores/root.store';
import { fetchAction } from '../../../../shared/fetchAction';

const DEFAULT_ASS_USER_PARAMS = { page: 1, limit: 10, role: IAssesmentRoles['content-manager'] };

export class OrganizationInfoStore {

  public readonly rootStore: IRootStore;

  public data?: IOrganizationBasic = undefined;
  public loading: boolean = true;
  public changeModeratorLoading = false;
  public error?: string;

  public moderatorList: IAssesmentUser[] = [];
  public moderatorListTotal: number = 0;
  public moderatorListLoading: boolean = false;
  public moderatorListParams: IGetAssesmentUserParams = DEFAULT_ASS_USER_PARAMS;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
    reaction(() => this.moderatorListParams, this.getModeratorList);
  }

  public fetchOrganization = (id: string): void => {
    this.loading = true;

    fetchAction(() => OrganizationApi.getOrganization(id), {
      successCallback: (organization) => {
        this.data = organization;
      },
      errorCallback: (_, message) => {
        this.error = message;
      },
      finallyCallback: () => {
        this.loading = false;
      },
    });
  };

  public changeOrganizationActivity = (params: IOrganizationActivityParams): void => {
    this.loading = true;

    fetchAction(() => OrganizationApi.organizationActivity(params), {
      successCallback: (organization) => {
        this.data = organization;

        this.fetchOrganization(organization._id);
      },
      errorCallback: (_, message) => {
        this.error = message;
      },
      finallyCallback: () => {
        this.loading = false;
      },
    });
  };

  public changeModeratorListParams = (params: Partial<IGetAssesmentUserParams>) => {
    this.moderatorListParams = { ...DEFAULT_ASS_USER_PARAMS, ...params };
  };

  public getModeratorList = () => {
    this.moderatorListLoading = true;

    fetchAction(() => userApi.getAssesmentUsers(this.moderatorListParams), {
      successCallback: (result) => {
        const newList = this.moderatorListParams.page === 1 ? result.items : [...this.moderatorList, ...result.items];
        this.moderatorList = newList;
        this.moderatorListTotal = result.totalCount;
      },
      finallyCallback: () => {
        this.moderatorListLoading = false;
      },
    });
  };

  public changeOrganizationModerator = (body: IChangeOrganizationModerator): Promise<void> => {
    this.changeModeratorLoading = true;

    return fetchAction(() => OrganizationApi.asignOrganizationModerator(body), {
      successCallback: (organization) => {
        this.data = organization;
      },
      finallyCallback: () => {
        this.changeModeratorLoading = false;
      },
    });
  };

  public removeOrganizationModerator = (body: IChangeOrganizationModerator): void => {
    this.changeModeratorLoading = true;

    fetchAction(() => OrganizationApi.detachOrganizationModerator(body), {
      successCallback: (organization) => {
        this.data = organization;
      },
      finallyCallback: () => {
        this.changeModeratorLoading = false;
      },
    });
  };

  public get ModeratorTableData() {
    return this.data?.mod ? [{ ...this.data?.mod, _id: this.data?.mod?.id_auth }] : undefined;
  }

}
