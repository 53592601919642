import { FC, SVGProps } from 'react';

export const BigSituationIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="64" height="64" rx="12" fill="#EB5757" />
    <path
      d="M39 25L25 39M39 39L25 25"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
