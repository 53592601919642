import React, {
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link, generatePath } from 'react-router-dom';
import {
  Button,
  Pagination,
  Table,
} from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Input } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/input';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { useDebounceFn } from 'ahooks';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { DEFAULT_FORMAT_WITH_TIME, LIMIT_OPTIONS } from '../../../constants';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useAppStore } from '../../../stores/context.store';
import { BuildingsIcon } from '../../components/icons/buildings';
import { PlusIcon } from '../../components/icons/plus';
import { SearchIcon } from '../../components/icons/search';
import { Stub } from '../../components/stub';
import { CreateOrganizationModal } from './components';
import {
  IOrganizationsTableColumn,
  IOrganizationsTableData,
} from './OrganizationList.models';
import { useOrganizationListStyles } from './OrganizationList.styles';

const COLUMNS: IOrganizationsTableColumn[] = [
  {
    name: 'Организация',
    key: 'orgName',
  },
  {
    name: 'Дата создания',
    key: 'createdAt',
  },
  {
    name: 'Номер телефона',
    key: 'phone',
  },
  {
    name: 'E-mail',
    key: 'email',
  },
  {
    name: 'Статус',
    key: 'status',
  },
];

enum OrganizationStatusRu {
  active = 'Активно',
  blocked = 'Заблокированно',
}

export const OrganizationList: React.FC = observer(() => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const classes = useOrganizationListStyles();

  const { organizationListStore, organizationStore } = useAppStore();
  const {
    tableData,
    params,
    total,
    loading,
    maxPage,
    handleSearch,
    handleLimitPage,
    handleNextPage,
    handlePrevPage,
    reset,
  } = organizationListStore;

  const { run } = useDebounceFn(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearch(e.target.value || '');
    },
    {
      wait: 500,
    },
  );

  useEffect(() => reset, []);

  const rowRender = useCallback(
    (
      row: IOrganizationsTableData,
      rowProps: HTMLAttributes<HTMLDivElement>,
    ): ReactElement | null => (
      <Link
        className={cn(rowProps.className, classes.row, { [classes.tableRowStatusBlockedBg]: row.status === 'blocked' })}
        to={generatePath(BrowserRoute.organization, { id: row._id })}
      >
        <div className={cn(classes.tableBodyRow, classes.tableRowOrgName)}>
          {row.name}
        </div>
        <div className={classes.tableBodyRow}>
          {dayjs(row.created_at).format(DEFAULT_FORMAT_WITH_TIME)}
        </div>
        <div className={classes.tableBodyRow}>{row.phone}</div>
        <div className={classes.tableBodyRow}>{row.email}</div>
        <div className={cn(
          classes.tableBodyRow,
          classes.tableRowStatusActive,
          { [classes.tableRowStatusBlocked]: row.status === 'blocked' },
        )}>
          {OrganizationStatusRu[row.status]}
        </div>
      </Link>
    ),
    [],
  );

  const headerRowRender = ({
    name,
    key,
  }: IOrganizationsTableColumn): ReactElement => (
    <div
      className={cn(classes.tableHeaderRow, {
        [classes.tableHeaderOrgName]: key === 'orgName',
      })}
    >
      {name}
    </div>
  );

  const handleOpenModalHandler = () => {
    setIsOpenModal(true);
  };

  const handleCloseModalHandler = () => {
    setIsOpenModal(false);
  };

  const showStub = !loading && !params.name && !total;

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Оценка 360</div>
      <Paper className={classes.root}>
        <div className={classes.tableHeader}>
          <div>
            <Input
              state="default"
              className={classes.searchField}
              placeholder="Поиск по названию"
              icon={<SearchIcon />}
              onChange={run}
              disabled={showStub}
            />
          </div>
          <Button
            disabled={organizationStore.loading}
            size="sm"
            className={classes.addOrganizationBtn}
            onClick={handleOpenModalHandler}
          >
            <PlusIcon /> Добавить организацию
          </Button>
        </div>
        {showStub ? (
          <Stub
            className={classes.emptyRoot}
            text="Здесь будут отображаться организации, которые вы добавите"
            icon={<BuildingsIcon />}
          />
        ) : (
          <>
            <Table
              loading={loading}
              columns={COLUMNS}
              data={tableData}
              className={classes.table}
              rowRender={rowRender}
              headerRowRender={headerRowRender}
            />
            <Pagination
              limit={params.limit}
              limitsOptions={LIMIT_OPTIONS}
              onLimitChange={handleLimitPage}
              total={total}
              minPage={1}
              maxPage={maxPage}
              page={params.page}
              onPrevClick={handlePrevPage}
              onNextClick={handleNextPage}
              loading={loading}
            />
          </>
        )}
      </Paper>
      <CreateOrganizationModal
        isOpen={isOpenModal}
        handleClose={handleCloseModalHandler}
      />
    </div>
  );
});
